<template>
  <hcc-modal
    name="assign-admin-modal"
    :title="modalName"
    :width="800"
    :confirmDisableButton="selectedAdmins.length === 0"
    @confirm="handleConfirm"
    @opened="getAdmins"
    @closed="clear"
  >
    <div class="table-container" @click.stop="() => {}">
        <overlay-loader :loading="pending"/>
        <hcc-checkbox-table
          :searchService="true"
          :columns="columns"
          :rows="admins"
          :pagination="pagination.total > 5"
          :rowsPerPage="5"
          :actualPage="pagination.page"
          :totalPages="pagination.total"
          @search="searchUser"
          @pageChanged="changePage"
          @checked="getCheckedAdmins"
          @check-all="getCheckedAdmins"
        />
    </div>
  </hcc-modal>
</template>

<script>
import { fetchUnassignedUsers } from '@/utils/userByCompany';
import HccModal from '@/components/shared/HccModal/index.vue';
import HccCheckboxTable from '@/components/shared/HccTable/HccCheckboxTable.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import assignUsers from '@/graphql/mutations/users/assignUsers.gql';
import errorHandler from '@/utils/error.handler';

export default {
  components: {
    HccModal,
    HccCheckboxTable,
    OverlayLoader,
  },
  props: {
    campaign: [Object, null],
  },
  data() {
    return {
      text: null,
      pagination: {
        total: 0,
        page: 1,
        length: 5,
      },
      admins: [],
      selectedAdmins: [],
      pending: null,
    };
  },
  computed: {
    modalName() {
      return this.$t('campaign-settings.assigned-users.assign-admin');
    },
    userLabel() {
      return this.$t('campaign-settings.assigned-users.user');
    },
    emailLabel() {
      return this.$t('campaign-settings.assigned-users.email');
    },
    columns() {
      return [{
        label: this.$t('campaign-settings.assigned-users.user'),
        field: 'name',
      }, {
        label: this.$t('campaign-settings.assigned-users.email'),
        field: 'email',
      }];
    },
  },
  methods: {
    getCheckedAdmins(rows) {
      this.selectedAdmins = rows;
    },
    async getAdmins() {
      this.pending = true;

      const result = await fetchUnassignedUsers(
        this.pagination.page,
        this.campaign.company,
        this.campaign.id,
        this.pagination.length,
        this.text,
        'admin',
      );

      this.pagination.total = result.total;
      this.pagination.page = result.page;

      this.admins = result.data;

      this.pending = false;
    },
    handleConfirm() {
      const params = this.selectedAdmins.map(admin => ({
        user: admin.id,
        campaign: this.campaign.id,
      }));

      this.assignAdminToCampaign(params);
    },
    async assignAdminToCampaign(users) {
      await this.$apollo.mutate({
        mutation: assignUsers,
        variables: { users, role: 'admin' },
      })
        .then(() => {
          this.$emit('updateAdmins');
          if (users.length > 1) {
            this.$toasted.global.success(this.$t('campaign-settings.assigned-users.assign-admins-success', { campaign: this.campaign.name }));
          } else {
            this.$toasted.global.success(this.$t('campaign-settings.assigned-users.assign-admin-success', { campaign: this.campaign.name }));
          }
        })
        .catch((err) => {
          errorHandler.logErrors(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        });
    },
    searchUser(value) {
      this.pagination.page = 1;
      if (value.length >= 3) {
        this.text = value;
      } else if (value.length === 0) {
        this.text = null;
      }
      this.getAdmins();
    },
    changePage(page) {
      this.pagination.page = page;
      this.getAdmins();
    },
    clear() {
      this.text = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/settings/_users-tab.scss";
</style>
