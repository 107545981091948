<template>
  <div class="loader-container">
    <div v-show="!canAssign" class="channels-warning">
      {{ channelsWarning }}
    </div>
    <overlay-loader :loading="pending"/>
    <hcc-table
      :pagination="pagination.totalPages > 5"
      :columns="columns"
      :rows="agents"
      :title="tableName"
      :buttonText="assign"
      @search="searchUser"
      :enableAdd="canAssign"
      @add="assignAgent"
      @edit="editAgent"
      @delete="deleteAgent"
      @pageChanged="changePage"
      :rowsPerPage="pagination.perPage"
      :actualPage="pagination.page"
      :totalPages="pagination.totalPages"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'channels'">
          <div
            v-for="channel in displayChannels(props.row.channels)"
            :key="channel.id"
            class="table__badge"
          >
            <channel-badge
              :type="channel.channelType.key"
              v-tooltip="channel.name"
            />
          </div>
          <span v-if="props.row.channels && props.row.channels.length > 5">...</span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </hcc-table>
    <edit-agent-modal
      :agent="agent"
      :campaign="campaign"
      @closed="agent = null"
      @updateAgents="updateAgents"
    />
    <assign-agent-modal
      v-if="canAssign"
      :campaign="campaign"
      @updateAgents="updateAgents"
    />
    <hcc-confirmation />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import HccTable from '@/components/shared/HccTable/index.vue';
import HccConfirmation from '@/components/shared/HccConfirmation/index.vue';
import ChannelBadge from '@/components/ChannelBadge.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import UsersByCampaignGql from '@/graphql/queries/users/usersByCampaign.gql';
import unassignUser from '@/graphql/mutations/users/unassignUser.gql';
import adminRole from '@/utils/adminRole';
import executeQuery from '@/utils/gql-api';
import errorHandler from '@/utils/error.handler';
import EditAgentModal from './EditAgentModal.vue';
import AssignAgentModal from './AssignAgentModal.vue';

export default {
  components: {
    HccTable,
    HccConfirmation,
    ChannelBadge,
    OverlayLoader,
    EditAgentModal,
    AssignAgentModal,
  },
  data() {
    return {
      channels: [],
      roles: ['owner', 'admin', 'super'],
      agentsByCampaign: [],
      agent: null,
      pending: null,
      text: null,
      pagination: {
        page: 1,
        perPage: 5,
        totalPages: 0,
      },
    };
  },
  watch: {
    campaign(newValue, oldValue) {
      if ((newValue.id !== oldValue.id && this.$route.name === 'campaigns-settings')
        || (newValue.id === oldValue.id && newValue.company)) {
        this.channels = newValue.channels;
        this.fetchAgents(1);
      }
    },
  },
  computed: {
    ...mapState({
      campaign: state => state.setting.currentCampaign,
    }),
    tableName() {
      return this.$t('campaign-settings.assigned-users.agents');
    },
    assign() {
      return this.$t('campaign-settings.assigned-users.assign');
    },
    delete() {
      return this.$t('campaign-settings.assigned-users.delete-agent');
    },
    channelsWarning() {
      return this.$t('campaign-settings.assigned-users.no-channels-warning');
    },
    agents() {
      return this.agentsByCampaign || [];
    },
    role() {
      return adminRole();
    },
    canAssign() {
      return this.roles.includes(this.role) && this.asChannels;
    },
    asChannels() {
      return this.channels.length > 0;
    },
    columns() {
      return [{
        label: this.$t('campaign-settings.assigned-users.user'),
        field: 'name',
      }, {
        label: this.$t('campaign-settings.assigned-users.email'),
        field: 'email',
      }, {
        label: this.$t('campaign-settings.assigned-users.channels'),
        field: 'channels',
        sortable: false,
      }];
    },
    emptyRows() {
      return this.pagination.totalPages === 0;
    },
  },
  methods: {
    ...mapActions('setting', ['updateCampaign']),
    ...mapMutations('shared', ['updateCampaignCompany']),
    displayChannels(channels) {
      if (channels) {
        if (channels.length > 5) {
          return channels.slice(0, 5);
        }
      }

      return channels || [];
    },
    async fetchAgents(page) {
      this.pending = true;
      const result = await executeQuery('usersByCampaign',
        UsersByCampaignGql,
        {
          role: 'agent',
          id: this.campaign.id,
          length: this.pagination.perPage,
          page,
          search: this.text,
        },
        false);

      let users = result.data;
      if (users) {
        users = users.map(({ campaigns, ...data }) => {
          const { channels } = campaigns.find(({ id }) => id === this.campaign.id);
          return { ...data, channels };
        });
      } else {
        users = [];
      }

      this.pending = false;
      this.agentsByCampaign = users;
      this.pagination.totalPages = result.total;
    },
    assignAgent() {
      this.$modal.show('assign-agent-modal');
    },
    async updateAgents() {
      await this.fetchAgents(1);
      this.updateCampaign({ ...this.campaign, agents: this.pagination.totalPages });

      const users = this.agents.reduce((acc, data) => {
        acc.push(data.id);
        return acc;
      }, []);

      this.updateCampaignCompany({
        id: this.campaign.company,
        idCampaing: this.campaign.id,
        users,
      });
    },
    editAgent(row) {
      this.agent = row;
      this.$modal.show('edit-agent-modal');
    },
    deleteAgent(row) {
      const info = {
        agent: row.name,
        campaign: this.campaign.name,
      };

      this.$modal.show('confirmation', {
        title: this.delete,
        description: this.$t('campaign-settings.assigned-users.confirm-delete-agent',
          info),
        variant: 'error',
        confirm: () => this.unassignAgent(row.id, info),
      });
    },
    async unassignAgent(agent, info) {
      await this.$apollo.mutate({
        mutation: unassignUser,
        variables: {
          user: agent,
          campaign: this.campaign.id,
        },
      })
        .then(() => {
          this.$toasted.global.success(this.$t('campaign-settings.assigned-users.agent-deleted', info));
          this.updateAgents();
        })
        .catch((err) => {
          errorHandler.logErrors(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        });
    },
    changePage(newPage) {
      if (this.pagination.page !== newPage) {
        this.pagination.page = newPage;
        this.fetchAgents(this.pagination.page);
      }
    },
    searchUser({ value, key }) {
      if (key && value.length >= 3) {
        this.text = value;
        this.fetchAgents(1);
      } else if (value.length === 0) {
        this.text = null;
        this.fetchAgents(1);
      }
    },
  },
  created() {
    this.fetchAgents(1);
    this.channels = this.campaign.channels;
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/settings/_users-tab.scss";
</style>
